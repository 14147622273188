// TOOLS & HELPERS - STYLES DEFINES

.logo {
    background: transparent url(../img/logo.svg) center no-repeat;
    background-size: contain;
}

.clear {
    clear: both;
}

.hide {
    display: none;
}

.hidden {
    visibility: hidden;
}

.blur {
    opacity: 0.4;
}

.center {
    text-align: center;
}

.legend {
    font-style: italic;
    color: #999;
}

.no-item {
    text-align: center;

    p {
        margin-bottom: 20px;
    }
} // .no-item

// MEMBRES MISE EN FORME
.prenom-membre,
.mbclub-prenom {
    text-transform: capitalize;
}

.nom-membre,
.mbclub-nom {
    text-transform: uppercase;
}

.msg-valid {
    color: @valid;

    i {
        display: inline-block;
        font-size: 1.2em;
        margin-right: 5px;
    }
} // .msg-valid

.bubble {
    height: 28px;
    width: 28px;
    font-size: 1.4rem;
    font-weight: 700;
    .flex-i(row,center,center);
    .rounded(50%);
}

.map {
    min-height: 300px;
    height: 300px;
    width: 100%;
    margin-bottom: 20px;
} // .map

.only-picto {
    > a {
        font-size: 2.4rem;
        margin-left: 20px;

        &:hover {
            i {
                .fas;
            }
        }
    }
} // .only-picto

.gauge {
    position: relative;
    height: 14px;
    width: 100%;
    background-color: #ccc;
    margin-bottom: 20px;
    .rounded(30px);

    &.master {
        height: 20px;
        font-size: 1.7rem;
        min-width: 350px;

        .fill {
            span {
                padding: 0 10px;
                width: 80px;
                margin-right: -40px;
                right: 0;
                opacity: 1;
            }
        }
    }

    &:hover {
        .fill {
            span {
                right: 0;
                opacity: 1;
            }
        }
    }

    .fill {
        display: inline-block;
        height: 100%;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
        background-color: @color;
        width: 0;
        max-width: 100%;
        .transition(all,500ms,ease-in-out);

        & + .fill {
            .rounded(0);
        }

        &.subscribed {
            background-color: @valid;
        }

        &.unsubscribed {
            background-color: @warning;
        }

        &.full {
            .rounded(30px);
        }

        .critical& {
            background-color: @alert;
        }

        .warning& {
            background-color: @warning;
        }

        .good& {
            background-color: @valid;
        }

        span {
            opacity: 0;
            position: absolute;
            right: 30px;
            top: -40px;
            margin-right: -35px;
            background-color: @color;
            color: #fff;
            width: 70px;
            padding: 0 5px;
            height: 30px;
            .flex(row,center,center);
            .rounded(5px);
            .transition;

            &::after {
                position: absolute;
                left: 50%;
                margin-left: -6px;
                bottom: -6px;
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 6px 0 6px;
                border-color: @color transparent transparent transparent;
            }

            .critical& {
                background-color: @alert;

                &::after {
                    border-color: @alert transparent transparent transparent;
                }
            }

            .warning& {
                background-color: @warning;

                &::after {
                    border-color: @warning transparent transparent transparent;
                }
            }

            .good& {
                background-color: @valid;

                &::after {
                    border-color: @valid transparent transparent transparent;
                }
            }
        }
    } // .fill
} // .gauge

.gauge-legend {
    li {
        font-style: italic;
        color: #ccc;
        .flex(row,center,flex-start);

        &::before {
            content: '';
            width: 15px;
            min-width: 15px;
            height: 15px;
            margin-right: 10px;
            background-color: #ccc;
            .rounded(50%);
        }

        &.subscribed {
            color: @valid;

            &::before {
                background-color: @valid;
            }
        }

        &.unsubscribed {
            color: @warning;

            &::before {
                background-color: @warning;
            }
        }
    }
} // .gauge-legend

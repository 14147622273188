/////// GENERAL /////

html {
    font-size: 62.5%;
}

body {
    background-color: #fff;
    font-size: 1.4rem;
    line-height: 1.4;
    font-family: @font;
    color: @fontcolor;
    min-height: 100vh;
    .flex(column,stretch,space-between);
}

a {
    color: @color;
    text-decoration: none;
    .transition;
}

strong,
b {
    font-weight: 700;
}

abbr {
    border-bottom: 1px dotted #555;
}

sup {
    vertical-align: super;
    font-size: 0.8em;
}

// HELPERS
.mb25 {
    margin-bottom: 25px;
}

.console {
    margin-bottom: 30px;
    font-family: 'Courier New', monospace;
    padding: 20px;
    background-color: #f6f6f6;

    .console {
        margin-bottom: 0;
        padding: 0;
    }

    p {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }

        &:empty {
            display: none;
        }
    }
}

// HEADINGS

h1,
.h1 {
    font-size: 2.5rem;
    margin-bottom: 25px;
    text-transform: uppercase;
    .flex(row, center,flex-start);
}

h2,
.h2 {
    font-size: 1.7rem;
    font-weight: 400;
    margin-bottom: 15px;

    &.big {
        font-weight: 700;
        border-bottom: 1px dashed tint(@color2, 60%);
        padding-bottom: 10px;
    }
}

h3,
.h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.6rem;
    margin-bottom: 20px;
    text-align: left;
}

h4,
.h4 {
    text-transform: uppercase;
    color: #666;
    font-size: 1.3rem;
    letter-spacing: 0.05rem;
    margin-bottom: 10px;
    padding-right: 15px;
}

////// CUSTOM PLUGINS /////

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background-color: @color;
    border-color: @color;
}

.cke {
    margin-bottom: 30px !important;
}

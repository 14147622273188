// PAGES

// ALL
.main-head {
    margin-bottom: 50px;
    .flex(row, center, flex-start);

    .action-back {
        margin-right: 20px;

        .btn {
            margin-left: 0;
        }
    }

    .btn {
        margin-left: 15px;
    }

    h1 {
        margin-bottom: 0;
        line-height: 1.1;
        flex-grow: 1;
        text-transform: uppercase;

        em {
            color: @color3;
        }

        > i {
            color: @color;
            font-size: 2.5rem;
            margin-right: 15px;
        }
    }

    .titre {
        .flex(column, flex-start, flex-start);

        > span {
            max-width: 100%;
            text-transform: none;

            strong {
                text-transform: uppercase;
            }
        }
    }

    .statut {
        margin-bottom: 10px;
    }

    .count-membres {
        font-size: 1.3rem;
        text-transform: none;
        background-color: @color;
        color: #fff;
        padding: 5px;
        .rounded(3px);
        .flex(row, center, flex-start);

        i {
            margin-right: 10px;
        }

        &.honneur {
            background-color: @color3;
            margin-left: 10px;
        }

        &.ha {
            background-color: shade(@color3, 40%);
            margin-left: 10px;
        }
    }

    .encart {
        font-size: 1.7rem;
        font-style: italic;
        color: #666;
        margin-bottom: 5px;
        text-transform: none;
    }

    .megafilter {
        label,
        input,
        .habillage,
        .choix-client {
            margin-left: 15px;
        }

        label {
            margin-right: 0;
        }

        .habillage {
            width: 170px;
        }

        .checkbox {
            margin-bottom: 0;

            label {
                margin-bottom: 0;
            }

            &.tag {
                label {
                    height: 40px;
                }
            }
        }

        .choix-client {
            input {
                margin: 0;
                width: auto;
            }
        }

        .date {
            max-width: 96px;
        }
    } // .megafilter

    nav {
        > * {
            margin-bottom: 0;
            margin-left: 15px;
        }

        .habillage {
            width: auto;
        }
    }
} // .main-head

// SECTION CONTENT
.section-bloc {
    margin-bottom: 15px;
    width: 100%;
    border: 1px solid tint(@color, 80%);
    .rounded(3px);

    &.valid {
        border-color: tint(@valid, 80%);
    }

    &.is-rodi {
        border-color: fadeout(@rodi, 70%);
    }

    &.rodi-sync {
        --form-color: @rodi;
    }
}

.section-content {
    padding: 10px;

    .head {
        margin-bottom: 35px;
        .flex();

        h3 {
            margin-bottom: 0;
            text-transform: none;
            font-size: 2rem;
            color: @color;
        }
    }

    &.nopad {
        padding: 0;
    }
}

.section-heading {
    background-color: tint(@color, 80%);
    color: @color;
    height: 44px;
    padding: 0 5px 0 10px;
    text-transform: uppercase;
    font-size: 1.65rem;
    font-weight: 700;
    margin-bottom: 0;
    .flex;

    .is-rodi > & {
        position: relative;
        background-color: fadeout(@rodi, 70%);
        color: shade(@rodi, 30%);

        &::after {
            position: absolute;
            right: 10px;
            top: 10px;
            display: inline-block;
            background-image: url('../img/logo-rodi.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 90px;
            height: 30px;
            content: '';
            .flex(row,center,center);
        }
    }

    .valid > & {
        color: @valid;
        background-color: tint(@valid, 80%);
    }

    > nav {
        .flex(row, center, flex-end);

        > * {
            margin-left: 10px;
        }
    }

    h3 {
        text-transform: uppercase;
        font-size: 1.6rem;
        margin-bottom: 20px;
    }

    em {
        font-size: 1.5rem;

        i {
            font-size: 2rem;
            margin-right: 5px;
        }
    }

    .btn,
    input,
    button {
        height: 34px;
    }

    .filter {
        i {
            top: 9px;
            color: @color;
        }

        input {
            background-color: transparent;
            border-color: @color;
            color: @color;

            &:focus {
                background-color: @color;
                color: #fff;

                + i {
                    color: #fff;
                }
            }
        }
    }

    .btn {
        border-color: transparent;
    }
} // .section-heading

// HOME

.welcome {
    text-align: center;
    margin-bottom: 50px;

    h2 {
        font-size: 3rem;
        font-weight: 300;
    }

    .btn {
        margin-bottom: 20px;
    }
}

.cgv-bloc {
    background-color: tint(@color3, 70%);
    padding: 20px;
    margin: auto;
    max-width: 700px;
    margin-bottom: 25px;
    .flex(column,center,center);
    .rounded(4px);

    p {
        margin-bottom: 20px;
    }
}

.first-coming {
    background-color: tint(@color, 90%);
    padding: 20px;
    margin: auto;
    max-width: 400px;
    margin-bottom: 25px;
    .flex(column,center,center);
    .rounded(4px);

    h3 {
        font-weight: 300;
        color: @color;
        font-size: 2rem;

        .membre &,
        .club & {
            margin-bottom: 0;
        }
    }

    p {
        margin-bottom: 10px;

        .membre &,
        .club & {
            margin-bottom: 0;
        }
    }

    .membre &,
    .club & {
        margin-bottom: 30px;
        max-width: 800px;
        .flex;
    }
} // .first-coming

.admin-tools,
.searching-blocs {
    margin-bottom: 40px;
}

.bloc-forking {
    margin: auto;
    margin-bottom: 25px;
    width: 400px;

    nav {
        margin-bottom: 5px;
    }

    .new-search {
        font-style: italic;
    }
}

.action-admin {
    text-align: center;
    margin-bottom: 60px;
}

.bloc-search-human,
.bloc-search-fact {
    width: 48%;
}

.bloc-search-human {
    .col a {
        display: block;
    }

    .btn-primary {
        margin-bottom: 30px;
    }
}

// LEGAL
.legal {
    .content {
        .flex(column,center,center);

        p {
            margin-bottom: 30px;
        }
    }
}

// DISTRICT

.item-slider {
    &.item-gouverneur {
        padding: 20px 5px;
        text-align: center;
    }
}

// GOUVERNEUR
.item-comite-single {
    margin: 10px;
}

// CLUB
.list-membres-comite {
    .row-item {
        background-color: tint(@color2, 85%);
        border-bottom: 1px solid #fff;

        &:hover {
            background-color: tint(@color2, 70%);
        }
    }

    .link-membre {
        flex-grow: 1;
        .flex(column,flex-start,flex-start);

        em {
            font-size: 1rem;
            font-style: italic;
        }
    }

    h4 {
        width: 130px;
        font-size: 1.2rem;
        margin-bottom: 0;

        .slider-comite-district & {
            width: 250px;
            min-width: 320px;
        }
    }

    p {
        flex-grow: 1;
        padding: 10px;
    }
} // .list-membres-comite

#commentaire-club {
    min-height: 250px;
}

// COMMANDE FORM

.bloc-quantity-product {
    font-size: 1.6rem;
    .flex(row, center, flex-start);

    strong {
        margin-left: 10px;
    }

    input {
        max-width: 100px;
        margin: 0 10px;
    }
}

.total-price-command {
    font-size: 2rem;
    color: @color;
}

.choix-reglement {
    align-items: stretch;
    margin: 30px 0;

    .col {
        > label {
            border: 1px solid #eee;
            padding: 5px;
            padding-top: 30px;
            height: 100%;
            text-align: center;
            cursor: pointer;
            .flex(column, center, flex-start);
            .rounded(3px);

            &:hover {
                background-color: #eee;
            }

            > i {
                color: @color2;
                font-size: 4rem;
                text-align: center;
                margin-bottom: 10px;
            }
        }

        h5 {
            font-weight: 700;
            margin-bottom: 25px;
            font-size: 1.5rem;
        }

        p {
            text-align: left;
        }

        .input-file {
            margin-bottom: 15px;
        }

        .file-selected {
            background-color: tint(@color, 50%);
            color: #fff;
            text-transform: none;

            &::before {
                color: @color;
            }

            &::after {
                border-color: transparent transparent tint(@color, 50%) transparent;
            }
        }
    } // .col

    input[type="radio"] {
        display: none;

        &:checked {
            & + label {
                position: relative;
                background-color: tint(@valid, 85%);
                border-color: @valid;

                > i,
                > h5 {
                    color: @valid;
                }

                &::before {
                    position: absolute;
                    top: -15px;
                    font-size: 2rem;
                    color: #fff;
                    background-color: @valid;
                    min-width: 30px;
                    width: 30px;
                    min-height: 30px;
                    .flex(row, center, center);
                    .fa;
                    .rounded(50%);

                    content: @fa-var-check;
                }
            }
        }
    }
}

.statut-annuaire {
    padding: 10px;
    min-height: 100px;
    min-width: 250px;
    margin-left: 25px;
    background-color: tint(@color, 85%);
    .flex(column, center, flex-start);

    &.invalid {
        background-color: tint(@warning, 85%);
    }

    &.valid {
        background-color: tint(@valid, 85%);
    }

    .btn {
        margin: auto;
    }

    h2 {
        font-weight: 700;
        text-align: center;
        color: @fontcolor;
        text-transform: uppercase;
        font-size: 1.4rem;
    }

    p {
        height: 40px;
        color: @valid;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
        .flex(row, center, center);

        i {
            height: 30px;
            width: 30px;
            background-color: @valid;
            color: #fff;
            margin-right: 10px;
            .flex(row, center, center);
            .rounded(50%);
        }
    }
} // .statut-annuaire

// MEMBRES
.bloc-conjoint {
    .flex;

    .infos-conjoint {
        em {
            color: @color2;
            font-style: italic;
        }

        span:not(.flag) {
            font-weight: 700;
            color: @color;
        }
    }

    nav {
        .flex(column, flex-end, center);

        .btn-del {
            margin-top: 10px;
            max-width: none;
        }
    }
}

#password {
    width: 100%;

    &.col {
        width: 50%;
    }
}

.wrap-autocomplete {
    position: relative;
    min-width: 450px;

    .results-autocomplete {
        position: absolute;
        top: 45px;
        left: 0;
        right: 0;
        z-index: 600;
        .flex(column, flex-start, flex-start);

        display: none;

        &.on-search {
            display: flex;
        }

        &::before {
            display: block;
            content: "";
            position: absolute;
            left: 20px;
            top: -14px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 14px 8px;
            border-color: transparent transparent @color transparent;
        }

        a {
            background-color: @color;
            color: #fff;
            height: 35px;
            width: 100%;
            padding: 0 15px;
            .flex();

            > span {
                &:empty {
                    &::before {
                        font-style: italic;
                        content: "Aucun club";
                    }
                }
            }

            &:hover {
                background-color: @color2;
            }
        }
    } // .results-membres
} // .wrap-choix-membre

// SEARCH ADRESS MEMBRE
.search-adr {
    margin-bottom: 25px;

    .btn {
        min-width: 120px;
    }
}

.results-adress {
    a {
        background-color: #eee;
        color: @color;
        height: 35px;
        width: 100%;
        padding: 0 15px;
        .flex(row, center, flex-start);

        span {
            margin: 0 2px;
        }

        &:nth-child(2n) {
            background-color: #ddd;
        }

        &:hover {
            background-color: @color2;
            color: #fff;
        }
    }
}

.abonnement {
    .abo {
        color: #fff;
        margin-bottom: 20px;
        padding: 10px;
        .flex(row, center, space-between);

        h3 {
            margin-bottom: 0;
            margin-right: 15px;
        }

        i {
            margin-right: 15px;
            font-size: 2rem;
        }

        &.actif {
            background-color: @color;
        }

        &.inactif {
            background-color: @warning;
        }
    }

    h2 {
        margin-bottom: 0;
    }
} // .abonnement

// EXPORT
.genere-routage {
    margin-bottom: 30px;
}

.choice-date {
    max-width: 500px;
}

.bloc-info-routage {
    background-color: tint(@color2, 80%);
    padding: 20px;
    margin-bottom: 25px;

    ul {
        margin-bottom: 20px;
    }

    li {
        margin-bottom: 5px;
    }

    h3 {
        color: @color;
    }

    a {
        margin-bottom: 25px;
    }
} // .bloc-export

.infos-abo-search {
    margin-bottom: 30px;
}

.recap-abonnes {
    min-width: 300px;
    margin-bottom: 0;
    .rounded(5px);

    h2 {
        text-align: center;
        color: @fontcolor;
    }

    h3 {
        text-align: center;
        font-size: 2rem;
        border: 1px solid @color2;
        color: @color;
        .rounded(5px);

        i {
            color: tint(@color2, 30%);
            margin-right: 10px;
        }
    }
}

.infographie-nb-abo {
    li {
        .flex(row, center, flex-start);
    }

    span {
        min-width: 150px;
        text-align: right;
        margin-right: 20px;
    }

    strong {
        background-color: @color;
        color: #fff;
        font-weight: 400;
        padding: 5px;
        .rounded(3px);
        .flex(row, center, center);
    }
} // .infographie-nb-abo

.search-abonne {
    color: @color;
    padding: 20px;
    flex-grow: 1;
    margin-left: 25px;
    border: 1px solid @color;
    .flex(column, flex-start, space-between);
    .rounded(3px);

    h2 {
        text-transform: uppercase;
    }

    .list-radio {
        flex-grow: 1;
    }
}

// PILOTAGE

.pilotage {
    .main-head {
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .legend {
        text-align: center;
    }

    .deadline {
        background-color: tint(@warning, 80%);
        padding: 10px;
        color: @warning;
        .flex(column, center, center);
        .rounded(5px);

        h3 {
            font-size: 1.5rem;
            margin-bottom: 5px;
            font-weight: 400;
        }

        p {
            font-size: 1.7rem;
        }

        i {
            display: inline-block;
            margin-right: 10px;
        }
    }
} // .pilotage

.pilotage-statut {
    background-color: #eee;
    padding: 10px;
    margin-bottom: 30px;
    .rounded(5px);

    h2 {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 40px;

        i {
            color: #999;
            margin-right: 10px;
            display: inline-block;
        }
    }

    .recap {
        margin-bottom: 20px;
        .flex(column,flex-start,flex-start);

        .gauge {
            margin-bottom: 20px;
        }
    }

    .details {
        margin-left: 25px;

        li {
            margin-bottom: 15px;
            .flex(row, center, flex-start);
        }

        h4 {
            min-width: 100px;
            margin-bottom: 0;
            text-align: right;
        }

        .gauge {
            min-width: 230px;
        }
    }

    .gauge {
        min-width: 330px;
        margin-right: 20px;
        margin-bottom: 0;
    }

    .total {
        .legend;

        font-style: normal;
        min-width: 160px;
        text-align: left;
    }

    &.solo-president {
        h2 {
            margin-bottom: 80px;
        }

        .recap {
            max-width: 500px;
            margin: auto;
        }
    }
} // .pilotage-statut

.reabo {
    margin-bottom: 20px;
    .flex(row,center,flex-start);

    h4 {
        min-width: 300px;
        text-align: right;
        margin-right: 20px;
    }

    progress {
        margin-right: 15px;
    }
}

.annuaire-papier-statut {
    min-width: 500px;
    padding: 10px;
    margin-bottom: 30px;
    margin-left: 30px;
    background-color: fadeout(@color, 80%);
    .rounded(5px);

    h2 {
        color: @color;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 10px;
    }

    .date-commande-open {
        font-style: italic;
        margin-bottom: 20px;
        text-align: center;
    }

    .big-data {
        color: @color;
        margin-bottom: 25px;
    }

    nav {
        .flex();
    }
} // .annuaire-papier-statut

.abo-statut {
    > div {
        width: 50%;
    }
}

.recap-total-abo {
    margin-left: 30px;
}

.mode-paiement {
    .flex(row,center,flex-start);

    .flag {
        margin-right: 10px;
    }

    p {
        min-height: 25px;
    }
}

.big-data {
    text-align: center;
    .flex(column,center,center);

    i {
        font-size: 2rem;
        opacity: 0.5;
        .fas;
    }

    strong {
        font-size: 2.5rem;
    }

    span {
        text-align: center;
        font-style: italic;
    }
} // .big-data

.montant-total-annuaire {
    text-transform: uppercase;
    color: #666;
    padding: 10px 15px;
    text-align: right;

    strong {
        color: @color;
        font-size: 2rem;
        margin-left: 10px;
    }
}

.search-fiches {
    max-width: 500px;
    margin: auto;
    margin-bottom: 30px;
    background-color: tint(@color2, 65%);
    padding: 15px;
    .rounded(5px);

    form {
        .flex(row, center, flex-start);

        input {
            margin-bottom: 0;
            .rounded(0);

            &[type="search"] {
                background-color: transparent;
                border-color: @color;
                color: @color;
                border-right: 0 none;
            }
        }
    }
}

// MAILING
.mail-info {
    margin-bottom: 15px;
    color: @color;

    i {
        font-size: 1.6rem;
        margin: 0 5px;
        opacity: 0.5;
        display: inline-block;
        font-weight: 400;
    }
}

.mail-content {
    padding: 10px;
    border: 2px dashed #eee;
}

// CONFIG

.list-config {
    flex-wrap: wrap;
    .flex(row,stretch,flex-start);

    display: none;

    &.open {
        display: flex;
    }
}

.item-config {
    width: 350px;
    border: 1px solid #eee;
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 25px;
    .rounded(3px);

    .legend {
        text-align: left;
    }

    input[type="submit"] {
        display: block;
    }
} // .item-config

.district {
    .row-item {
        > span {
            max-width: 800px;
        }
    }
} // .district

// EXPORT
.liste-champs {
    margin-bottom: 30px;
    .col(5, 228px, 15px);

    .item-champ {
        break-inside: avoid;
        margin-bottom: 5px;

        &:hover {
            .checkbox {
                opacity: 0.5;
            }
        }

        .checkbox {
            margin-right: 0;
            .flex(row-reverse, center, flex-end);

            label {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                flex-grow: 1;
            }
        } // .checkbox

        .get-filter {
            background-color: #eee;
            color: #999;
            height: 34px;
            width: 34px;
            min-width: 34px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            .flex(row, center, center);

            &:hover {
                background-color: #999;
                color: #fff;
            }

            &.active {
                color: @color;
                background-color: tint(@color2, 50%);

                i {
                    font-weight: 700;
                }

                &:hover {
                    background-color: @color;
                    color: #fff;
                }
            }
        } // .get-filter
    } // .item-champ
} // .liste-champs

.export-link {
    .flex(row, center, flex-start);

    &:hover {
        font-weight: 700;
    }

    i {
        margin-right: 10px;
    }
}

.item-export {
    padding-left: 10px;
    .flex;

    &:hover {
        background-color: #eee;
    }
}

// VALIDATION CLUB
.validation-club {
    .fiche-valid {
        font-size: 1.43rem;
        font-weight: 400;
        margin-right: 10px;
        .flex(row, center, flex-end);

        i {
            font-size: 2rem;
            margin-right: 10px;
        }
    }
}

.list-membres-tovalid {
    padding-left: 30px;

    .item-membre {
        position: relative;
        float: left;
        margin-bottom: 10px;
        margin-right: 10px;
        width: 278px;
        min-height: 550px;
        font-size: 1.3rem;
        padding: 10px;
        .rounded(3px);

        section {
            > i {
                height: 30px;
                width: 30px;
                margin-right: 5px;
                color: #fff;
                .rounded(50%);
                .fas;
                .flex-i(row, center, center);
            }
        }

        &.valid {
            background-color: tint(@valid, 80%);

            section {
                > i {
                    background-color: @valid;
                }
            }
        }

        &.tovalid {
            background-color: tint(@color2, 80%);

            section {
                > i {
                    background-color: @color2;
                }
            }

            &:hover {
                .pop {
                    opacity: 1;
                }
            }
        }

        .pop {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            background-color: fadeout(@color, 10%);
            color: #fff;
            font-size: 1.7rem;
            cursor: pointer;
            .flex(column, center, center);
            .transition;
            .rounded(3px);

            span {
                text-transform: uppercase;
            }

            i {
                font-size: 3rem;
                margin-bottom: 10px;
            }
        }
    } // .item-membre
} // .list-membres-tovalid

.logs {
    [data-tooltip] {
        &:hover {
            i {
                color: @color;
            }
        }
    }
} // .Logs

.infolog {
    .log-type {
        width: 65px;
    }

    .log-user {
        width: 200px;
    }

    .log-champ {
        width: 300px;
    }

    .log-val {
        min-width: 300px;
    }
}

.img-profil {
    img {
        max-width: 300px;
        display: block;
    }
}

.export-imprimeur {
    margin-bottom: 25px;
    text-align: center;

    p {
        text-align: center;
        margin-bottom: 20px;
    }
}

// LIST CLIENT
.email-client {
    color: tint(@color, 50%);
    font-style: italic;
}

// CLIENT
.client {
    .abo {
        background-color: @color;
        color: #fff;
        padding: 0 10px;
        min-height: 35px;
        .rounded(4px);
        .flex;

        i {
            margin-right: 10px;
            font-size: 1.7rem;
            .fas;
        }
    }

    .abo-status {
        margin-bottom: 10px;
        .flex(row,center,flex-start);

        strong {
            margin: 0 5px;
        }

        i {
            font-size: 1.7rem;
            color: @color;
            margin-right: 10px;
            .fas;
        }
    }

    .abo-motif {
        margin-bottom: 10px;

        strong {
            margin: 0 5px;
        }

        i {
            font-size: 1.7rem;
            color: @color;
            margin-right: 10px;
            .fas;
        }
    }
} // .client

// COMPTA
.compta {
    .bloc-compte {
        min-width: 400px;
    }

    .compta-doc {
        margin-left: 20px;
        flex-grow: 1;
    }

    .paid-status {
        &.canceled {
            color: @alert;
        }

        &.no-paid {
            color: @warning;
        }

        &.paid-ok {
            color: @valid;
        }

        i {
            font-size: 1.7rem;
            .fas;
        }
    }

    .nom-facture {
        em {
            font-style: italic;
            color: #999;
        }
    }

    .list-ope {
        td {
            height: 40px;
            padding: 0 10px;
            border-left: 1px solid #eee;

            &.val {
                width: 80px;
                padding-right: 0;
                text-align: center;
            }
        }
    }

    .invoice-status {
        li {
            margin-bottom: 10px;
            .flex(row,center,flex-start);

            i {
                color: @color2;
                margin-right: 5px;
                .fas;
            }

            strong {
                margin: 0 3px;
            }
        }
    } // .invoice-status
} // .compta

// LIST FACTURES
.row-facture {
    .flag {
        margin-right: 10px;
    }
}

// Commandes Eshop
.infos-commande {
    margin-bottom: 40px;

    .legend {
        margin-bottom: 20px;
    }
}

.infos-client {
    width: 100%;
}

.bloc-infos {
    background-color: #eee;
    padding: 10px;
    width: 49%;

    h2 {
        border-bottom: 1px dashed #ccc;
        padding-bottom: 5px;
        text-transform: uppercase;
    }

    h3 {
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    a {
        color: #333;

        &:hover {
            color: @color3;
        }
    }

    p {
        margin-bottom: 10px;

        span {
            display: block;
            text-align: left;
            border-left: 1px dashed #ccc;
            padding-left: 10px;
        }
    }

    i {
        opacity: 0.4;
        margin-right: 10px;
    }

    .cols {
        .flex(row,center,flex-start);

        .cols {
            margin-right: 30px;
        }
    }
}

.recap-panier {
    padding-right: 20px;
    .flex(column,flex-end,flex-start);

    p {
        height: 26px;
        .flex;

        &.recap-final {
            font-weight: 700;
            font-size: 1.7rem;
            border-top: 1px dashed #ccc;
            padding-top: 5px;

            span {
                color: #333;
                font-weight: 700;
            }
        }

        span {
            min-width: 200px;
            text-align: right;
            margin-bottom: 0;
        }
    }

    .montant {
        min-width: 130px;
    }
} // .recap-panier

// FACTURE ADD
.facture-add {
    .listing {
        margin-bottom: 20px;
    }

    .checkbox.tag {
        margin-bottom: 0;
        margin-right: 0;

        label {
            background-color: transparent;
            border-color: transparent;
            color: @color;

            &::after {
                color: @color;
            }
        }
    }

    .summary {
        margin-bottom: 20px;
        .flex(row,center,flex-end);
    }
} // .facture-add

.abo-club {
    .row-item {
        .member {
            margin-right: 10px;
            border-color: @color;
            color: @color;
        }

        &:hover {
            background-color: shade(@color, 40%);

            .member {
                border-color: #fff;
                color: #fff;
            }
        }
    }
} // .abo-club

.info-date-routage {
    margin-bottom: 25px;
}

// Données annuaires
.annuaire {
    .nav-onglets a {
        max-width: 350px;
    }
}

.infos-payment {
    p {
        margin-bottom: 20px;
    }

    .pricing {
        color: #fff;
        padding: 10px;
        text-transform: uppercase;
        background-color: tint(@color2, 30%);
        .flex;

        strong {
            font-size: 3rem;
            color: @color;
        }
    }

    ul {
        margin-bottom: 20px;
    }
}

.bloc-rib {
    padding: 10px;
    background-color: #eee;
    color: #666;

    strong {
        color: @fontcolor;
    }
}

//////// ADMIN FORMS  ///////

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

:root {
    --form-color: @color;
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="tel"],
input[type="url"],
textarea {
    appearance: none;
    border: 1px solid #eee;
    color: @fontcolor;
    padding: 0 10px;
    height: 40px;
    background-color: #eee;
    font-size: 1.3rem;
    font-family: @font;
    margin-bottom: 20px;
    width: 100%;
    .rounded(2px);
    .placeholder;

    &:focus {
        outline: none;
        background-color: transparent;
        border-color: var(--form-color);
        color: var(--form-color);
    }

    .form-variant & {
        background-color: #fff;
        border-color: #fff;
    }

    .cols > & {
        margin-bottom: 0;
    }

    .locked &,
    &[disabled],
    &[readonly]:not(.date),
    &[readonly]:not(.datetime) {
        --form-color: #666;

        background-color: #fff;
        border: 1px solid #ccc;
        .placeholder(#fff,0);
    }
}

input[type="submit"] {
    appearance: none;
    border: 1px solid var(--form-color);
    font-size: 1.1rem;
    text-align: center;
    padding: 0 10px;
    height: 40px;
    background-color: var(--form-color);
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    cursor: pointer;
    .rounded(2px);

    &:hover {
        background-color: transparent;
        color: var(--form-color);
    }

    &[disabled] {
        display: none;
    }
}

button {
    appearance: none;
    border: 0 none;
    background: none;
    cursor: pointer;
}

textarea {
    resize: vertical;
    padding: 10px;
    min-height: 150px;

    &.ckeditor {
        margin-bottom: 30px;
    }
}

label,
.label {
    display: block;
    text-transform: uppercase;
    color: #666;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
    margin-bottom: 10px;
    padding-right: 15px;

    em {
        text-transform: none;
        display: block;
        font-size: 1.25rem;
        letter-spacing: 0;
        font-weight: 400;
        font-style: italic;
        color: #aaa;
    }

    .cols > & {
        margin-bottom: 0;
    }

    .hright > & {
        margin-right: 15px;
    }
}

// PROGRESS
@keyframes avancing-progress {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 100% 100%;
    }
}

progress {
    appearance: none;
    border: 0 none;
    max-width: 600px;
    width: 100%;
    height: 25px;
    overflow: hidden;
    .rounded(20px);
}

::-webkit-progress-bar {
    background-color: #eee;
    .rounded(20px);
}

::-ms-fill,
::-moz-progress-bar,
::-webkit-progress-value {
    background-color: var(--form-color);
}

///// SELECT CUSTOM ////

.habillage {
    height: 40px;
    width: 100%;
    min-width: 250px;
    max-width: 400px;
    margin-bottom: 20px;
    position: relative;
    background-color: #eee;
    border: 1px solid #eee;
    .rounded(2px);

    .locked &,
    &.disabled {
        background-color: transparent;
        border-color: #ccc;
    }

    .form-variant & {
        background-color: #fff;
        border-color: #fff;
    }

    &::after {
        .fa;
        .fal;

        position: absolute;
        right: 0;
        top: 0;
        content: @fa-var-angle-down;
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 30px;
        color: #666;

        .locked & {
            display: none;
        }
    }

    select {
        position: absolute;
        left: 0;
        top: 0;
        appearance: none;
        text-overflow: '';
        border: 0 none;
        background-color: transparent;
        font-family: inherit;
        font-size: 1.3rem;
        height: 100%;
        width: 100%;
        padding-left: 10px;
        padding-right: 30px;
        z-index: 99;
    }
}

////// BOUTONS CHECKBOX /////

.checkbox {
    position: relative;

    label {
        font-family: @font;
        text-transform: none;
        padding-right: 15px;
        position: relative;
        cursor: pointer;
        .flex(row,center,flex-start);

        .locked & {
            --form-color: #666;

            cursor: default;
        }

        &::before {
            content: '';
            height: 20px;
            width: 20px;
            min-width: 20px;
            border: 2px solid #eee;
            background-color: #eee;
            margin-right: 8px;
            .rounded(3px);
            .transition;

            .form-variant & {
                background-color: #fff;
                border-color: #fff;
            }

            .locked & {
                background-color: #fff;
            }
        }

        &::after {
            .fa;
            .far;

            position: absolute;
            left: 4px;
            top: 50%;
            margin-top: -6px;
            content: @fa-var-check;
            color: #eee;
            font-size: 1.3rem;
            .transition;

            .form-variant &,
            .locked & {
                color: #fff;
            }
        }
    }

    input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
        border: 0 none;
        z-index: -1;
        appearance: none;
        outline: none;

        &:checked {
            & + label {
                color: var(--form-color);

                &::before {
                    background-color: #fff;
                    border-color: var(--form-color);
                }

                &::after {
                    color: var(--form-color);
                }
            }
        }

        &:disabled {
            & + label {
                cursor: not-allowed;

                &::before {
                    border-color: #ccc;
                    border-width: 1px;
                    background-color: transparent;
                    .rounded(0);
                }
            }
        }
    }

    &.tag {
        display: inline-block;
        margin-bottom: 10px;
        margin-right: 10px;

        label {
            background-color: #eee;
            border: 1px solid #eee;
            color: #aaa;
            font-style: italic;
            height: 34px;
            padding: 0 10px 0 30px;
            margin-bottom: 0;
            .rounded(3px);
            .flex-i;

            .form-variant & {
                background-color: #fff;
                border-color: #fff;
            }

            &::before {
                display: none;
            }

            &::after {
                top: 50%;
                left: 9px;
                font-style: normal;
                color: #aaa;
                content: @fa-var-times;
            }
        }

        input[type="checkbox"] {
            display: none;

            &:checked {
                & + label {
                    background-color: var(--form-color);
                    border-color: var(--form-color);
                    color: #fff;
                    font-style: normal;

                    &::after {
                        color: #fff;
                        content: @fa-var-check;
                    }
                }
            }
        }
    }
} // checkbox

///// LISTE DE BOUTONS RADIO

.list-radio {
    position: relative;
    margin-bottom: 13px;

    .locked & {
        --form-color: #666;
    }

    li {
        margin-bottom: 5px;
    }

    label {
        position: relative;
        display: flex;
        text-transform: none;
        color: #333;
        font-family: @font;

        .locked & {
            display: none;
        }

        &::before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            background-color: #eee;
            border: 1px solid transparent;
            .rounded(50%);
            .transition;

            .form-variant & {
                background-color: #fff;
            }
        }

        &::after {
            position: absolute;
            left: 6px;
            top: 6px;
            content: '';
            height: 8px;
            width: 8px;
            background-color: transparent;
            .rounded(50%);
            .transition;
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
        border: 0 none;
        z-index: -1;
        appearance: none;
        .rounded(50%);

        &:checked {
            & + label {
                font-weight: 700;

                .locked & {
                    display: flex;
                }

                &::before {
                    border-color: var(--form-color);
                    background-color: transparent;

                    .form-variant & {
                        background-color: transparent;
                    }
                }

                &::after {
                    background-color: var(--form-color);
                }
            }
        }
    }

    &.inline {
        li {
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 0;
        }

        label {
            display: inline-flex;

            .locked & {
                display: none;
            }
        }

        input {
            &:checked {
                & + label {
                    .locked & {
                        display: inline-flex;
                    }
                }
            }
        }

        .cols & {
            margin-bottom: 0;
        }
    } // .inline
} // .list-radio

///// INPUT FILE /////

.input-file {
    margin-bottom: 20px;
    width: auto;

    label {
        cursor: pointer;
        margin-bottom: 0;
    }

    i {
        font-size: 2.5rem;
        margin-right: 5px;
    }

    input {
        display: none;
    }

    .file-selected {
        font-style: italic;
        font-weight: 700;
        text-align: left;
        padding: 0;
        margin-bottom: 0;
        position: relative;

        &:not(:empty) {
            background-color: #eee;
            margin-bottom: 20px;
            margin-top: 15px;
            padding: 10px;
            .rounded(3px);

            &::before {
                content: "Fichier sélectionné : ";
                font-style: normal;
                font-weight: normal;
                display: block;
                color: @color2;

                [lang="en"] & {
                    content: "File selected: ";
                }
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 15px;
                top: -10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12px 10px 12px;
                border-color: transparent transparent #eee transparent;
            }
        }
    } // .file-selected
} // .input-file

// FILTER

.filter {
    position: relative;
    .flex(row,center,flex-start);

    i {
        position: absolute;
        left: 10px;
        top: 12px;
        font-size: 1.8rem;
        color: tint(@color, 60%);
    }

    input:not([type="submit"]) {
        padding-left: 40px;
        margin-bottom: 0;

        &:focus {
            + i {
                color: var(--form-color);
            }
        }
    }

    input[type="submit"] {
        margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
} // .filter

// CLASS FORMATAGE //

.col-number {
    max-width: 200px;
}

.civilite {
    max-width: 100px;
}

.cp {
    margin-right: 15px;
    max-width: 130px;
}

.ville {
    flex-grow: 1;
}

.pays {
    margin-left: 20px;
}

.tel,
.date,
.year {
    max-width: 200px;
}

form {
    p {
        margin-bottom: 25px;
    }
}

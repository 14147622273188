// LAYOUT

.wrap {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    position: relative;
}

/// LAYOUT ///

.content {
    flex-grow: 1;
    padding-bottom: 30px;
    .wrap;

    &.full {
        max-width: none;
        width: auto;
        margin: 0 25px;
    }
}

.cols {
    .flex(row,flex-start,space-between);

    &.vertical {
        flex-direction: column;
    }

    &.no-sb,
    &.hleft {
        justify-content: flex-start;
    }

    &.hcenter {
        justify-content: center;
    }

    &.vcenter {
        align-items: center;
    }

    &.vbottom {
        align-items: flex-end;

        .habillage {
            margin-bottom: 0;
        }
    }

    &.vstretch {
        align-items: stretch;
    }

    &.hright {
        justify-content: flex-end;
    }

    .col {
        width: 50%;
        margin-left: 15px;

        &:first-child {
            margin-left: 0;
        }

        &.verysmall {
            max-width: 210px;

            .habillage {
                min-width: auto;
            }

            & + .col {
                flex-grow: 1;
            }
        }

        &.date-size {
            max-width: 250px;
        }

        &.full {
            width: 100%;
        }
    }
} // .cols

/// ONGLETS
.nav-onglets {
    .flex(row,center,center);

    a {
        width: 100%;
        max-width: 250px;
        height: 45px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 25px;
        border-bottom: 1px solid #ccc;
        .flex(row,center,center);

        &.actif,
        &:hover {
            color: @color;
            font-weight: 700;
            border-bottom-color: @color;
        }

        &.disabled {
            font-style: italic;
            color: #ccc;

            &:hover {
                font-weight: 400;
                border-bottom-color: #ccc;
            }
        }

        &:only-child {
            display: none;
        }
    }

    .bubble {
        height: 26px;
        width: 26px;
        font-size: 1.3rem;
        background-color: @color;
        color: #fff;
        margin-left: 10px;
    }
} // .nav-onglets

.onglet {
    display: none;

    &.open {
        display: block;

        &.cols {
            display: flex;
        }
    }
} // .onglet

/// SECTION TOGGLE
.toggle-section {
    margin-bottom: 25px;

    .toggle-head {
        position: relative;
        background-color: fadeout(@color2, 70%);
        padding: 0 50px 0 15px;
        color: @color;
        margin-bottom: 0;
        height: 45px;
        cursor: pointer;
        .flex;

        &::after {
            position: absolute;
            right: 14px;
            top: 50%;
            margin-top: -8px;
            font-size: 1.6rem;
            .fa;
            .transition;

            content: @fa-var-arrow-down;
        }

        .bubble {
            margin-left: 10px;
            background-color: shade(@color, 40%);
            color: #fff;
        }

        em {
            flex-grow: 1;
        }
    }

    .toggle-content {
        display: none;

        &.pad25 {
            padding: 20px 0;
        }
    }

    &.open {
        .toggle-head {
            background-color: @color;
            color: #fff;

            &::after {
                .rotate(-180deg);
            }
        }

        .toggle-content {
            display: block;
        }
    }
} // .toggle-section

.toggle {
    display: none;

    &.open {
        display: block;
    }
}

// LISTE ROWS
.list-rows {
    margin-bottom: 30px;
}

.row-item {
    min-height: 50px;
    padding: 0 15px;
    .flex;

    > span {
        strong {
            text-transform: uppercase;
        }
    }

    &.row-membre {
        height: 70px;

        > span:not(.list-tags) {
            .flex(column,flex-start,flex-start);
        }

        .main-infos-membre {
            margin-bottom: 5px;
        }
    }

    .list-tags {
        .flag {
            margin-top: 0;
        }

        i {
            margin-left: 10px;
        }

        .txt-info {
            text-transform: uppercase;
            font-size: 1.26rem;
        }
    }

    a& {
        color: @color;

        &:hover {
            background-color: @color;
            color: #fff;
        }

        .fa-angle-right {
            font-size: 1.8rem;
        }
    }

    &:nth-child(odd) {
        background-color: #eee;
    }

    .nom {
        em {
            color: @color2;
        }
    }

    .secondary {
        font-style: italic;
        opacity: 0.8;
        margin-right: 15px;
    }
} // .row-item

// LIST COLS
.list-cols {
    .col(2,49%,0);

    margin-bottom: 25px;
} // . list-cols

.col-item {
    .flex(row,center,flex-start);

    i {
        margin-right: 10px;
        font-size: 2rem;
    }

    .btn-edit {
        margin-left: 10px;
        background-color: #eee;
        justify-content: center;
    }

    a&,
    a {
        min-height: 40px;
        padding: 0 15px;
        .flex(row, center, flex-start);

        &:hover {
            background-color: @color;
            color: #fff;
        }
    }
} // .col-item

// TABLEAUX
.listing {
    width: 100%;
    margin-bottom: 25px;

    .section-content & {
        margin: auto;
        max-width: 1170px;
    }

    thead {
        tr {
            background-color: transparent;
        }
    }

    tbody {
        border: 1px solid #eee;

        tr {
            border-top: 1px solid #eee;

            &:first-child {
                border: 0 none;
            }

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }

    th {
        text-transform: uppercase;
        font-weight: 700;
        height: 60px;
        font-size: 1.35rem;
        color: #999;
        text-align: left;
        vertical-align: middle;

        &:last-child {
            text-align: right;
        }
    }

    td {
        height: 65px;
        padding: 0 5px;
        font-size: 1.35rem;
        vertical-align: middle;

        img {
            max-height: 100px;
        }

        &:first-child {
            padding-left: 15px;
        }

        .habillage,
        input {
            margin-bottom: 0;
            border: 1px solid #ccc;
        }

        .popup {
            .habillage,
            input {
                margin-bottom: 20px;
                border: 0 none;
            }
        }

        .inactif,
        .actif {
            max-width: 110px;
        }

        &.rang {
            padding-left: 15px;
            color: @fontcolor;
        }

        &.statut,
        &.date {
            width: 120px;
        }

        &.time {
            width: 150px;
        }

        &.date-user {
            width: 200px;

            span {
                font-weight: 700;
            }

            em {
                display: block;
                color: tint(@color2, 30%);
                font-style: italic;
                font-size: 1.3rem;
            }
        }

        .btn {
            max-height: 34px;
        }

        &:last-child {
            text-align: right;
            padding-right: 15px;
        }

        &.user {
            min-width: 300px;
            width: 200px;
        }

        &.text {
            width: 280px;
        }

        &.full {
            width: 100%;
        }

        &.action {
            width: 60px;

            .btn {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                max-width: 34px;
                height: 34px;
                margin-left: 5px;

                i {
                    font-size: 1.8rem;
                    flex-grow: 0;
                }
            }
        }
    } // td

    .input-file {
        .flex(row,center,flex-start);

        .file-selected:not(:empty) {
            padding: 0;
            padding-left: 10px;
        }
    }

    .form-file {
        .flex(row,center,flex-start);

        input {
            height: 34px;
            margin-left: 10px;
        }
    }
} // listing

// COMPONENTS

//// BOUTONS ////
.btn {
    height: 40px;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.2rem;
    border: 1px solid transparent;
    padding: 0 10px;
    .rounded(2px);
    .flex-i(row, center, center);

    &.btn-sml {
        height: 34px;
    }

    &.btn-primary {
        background-color: @color;
        border-color: @color;
        color: #fff;

        &:hover,
        &.actif {
            background-color: transparent;
            color: @color;
        }
    }

    &.btn-primary-invert {
        background-color: transparent;
        border-color: @color;
        color: @color;

        &:hover,
        &.actif {
            background-color: @color;
            color: #fff;
        }
    }

    &.btn-secondary {
        background-color: tint(@color2, 75%);
        border-color: tint(@color2, 75%);
        color: @color;

        &:hover,
        &.actif {
            background-color: transparent;
            border-color: @color;
        }

        &.hover-special {
            &:hover {
                background-color: @color2;
                color: white;
            }
        }
    }

    &.btn-tertiary {
        background-color: @color3;
        border-color: @color3;
        color: #fff;

        &:hover {
            background-color: transparent;
            color: @color3;
        }
    }

    i {
        margin-right: 10px;
        font-size: 1.8rem;

        &:only-child {
            margin-right: 0;
        }
    }

    &.disabled {
        font-style: italic;
        color: #999;
        border-color: #ccc;
        background-color: #ccc;
        cursor: not-allowed;

        &:hover {
            background-color: #ccc;
            border-color: #ccc;
            color: #999;
        }
    }

    &.btn-del,
    &.btn-inactif {
        border-color: @alert;
        color: @alert;
        max-width: 150px;

        span {
            margin-left: 5px;
        }

        &:hover {
            background-color: @alert;
            color: #fff;
        }

        &.full {
            background-color: @alert;
            color: #fff;

            &:hover {
                color: @alert;
                background-color: transparent;
            }
        }

        &.fixed {
            &.fixed {
                border-color: transparent;

                &:hover {
                    background-color: transparent;
                    color: @alert;
                }
            }
        }
    }

    &.btn-actif,
    &.btn-valid {
        background-color: transparent;
        border-color: @valid;
        color: @valid;

        &:hover {
            background-color: @valid;
            color: #fff;
        }

        &.fixed {
            border-color: transparent;

            &:hover {
                background-color: transparent;
                color: @valid;
            }
        }
    }

    &.btn-warning {
        background-color: transparent;
        border-color: @warning;
        color: @warning;

        &:hover {
            background-color: @warning;
            color: #fff;
        }

        &.fixed {
            border-color: transparent;

            &:hover {
                background-color: transparent;
                color: @warning;
            }
        }
    }

    &.btn-alert {
        background-color: transparent;
        border-color: @alert;
        color: @alert;

        &:hover {
            background-color: @alert;
            color: #fff;
        }

        &.fixed {
            border-color: transparent;

            &:hover {
                background-color: transparent;
                color: @alert;
            }
        }
    }

    &.btn-edit {
        max-width: 40px;
        min-height: 40px;
        .rounded(50%);
    }
} // .btn

.btn-copy {
    cursor: pointer;

    &:hover {
        color: @color;

        &::before {
            .fa;

            color: @color2;
            content: @fa-var-copy;
            opacity: 1;
            .transition;
        }
    }
}

.flag {
    height: 25px;
    margin-left: 5px;
    margin-top: -5px;
    padding: 0 5px;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.1rem;
    color: #fff;
    .flex-i(row, center, center);
    .rounded(2px);

    &::before {
        font-size: 1.4rem;
        margin-right: 5px;
        .fa;
        .far;
    }

    &.valid {
        background-color: @valid;

        &::before {
            content: @fa-var-check;
        }
    }

    &.invalid {
        background-color: @alert;

        &::before {
            content: @fa-var-times;
        }
    }

    &.warning {
        background-color: @warning;

        &::before {
            content: @fa-var-exclamation-triangle;
        }
    }

    &.tf-F {
        background-color: @color;
    }

    &.tf-A {
        background-color: @color2;
    }

    &.dissous,
    &.mr {
        background-color: @alert;

        &::before {
            content: @fa-var-ban;
        }
    }

    &.radie {
        background-color: @warning;

        &::before {
            .fab;

            content: @fa-var-creative-commons-nc-eu;
        }
    }

    &.mh {
        background-color: @color3;

        &::before {
            content: @fa-var-crown;
        }
    }

    &.ha {
        background-color: shade(@color3, 40%);

        &::before {
            content: @fa-var-eye-slash;
        }
    }

    &.transit {
        background-color: shade(@color, 40%);

        &::before {
            content: @fa-var-plane;
        }
    }

    &.conjoint {
        background-color: shade(@color2, 20%);

        &::before {
            content: @fa-var-rings-wedding;
        }
    }

    &.state-commande {
        &.valid {
            &::before {
                content: @fa-var-shield-check;
            }
        }

        &.invalid {
            &::before {
                content: @fa-var-cash-register;
            }
        }
    }

    &.send-mail {
        &.valid {
            background-color: @valid;

            &::before {
                content: @fa-var-paper-plane;
            }
        }

        &.invalid {
            &::before {
                content: @fa-var-paper-plane;
            }
        }
    }

    &.mandat {
        background-color: @color2;

        &::before {
            content: @fa-var-calendar-week;
        }
    }

    &.abo-type-indiv {
        background-color: @color2;

        &::before {
            content: @fa-var-user;
        }
    }

    &.abo-type-club {
        background-color: @color;

        &::before {
            content: @fa-var-club;
        }
    }

    &.abo-type-free {
        background-color: @color3;

        &::before {
            content: @fa-var-gift;
        }
    }

    &.is-abo {
        background-color: @color2;

        &::before {
            content: @fa-var-book-open;
        }
    }

    &.member {
        background-color: transparent;
        border: 1px solid tint(@color, 40%);
        color: tint(@color, 40%);

        &::before {
            content: @fa-var-user-tie;
        }
    }

    &.memberh {
        background-color: transparent;
        border: 1px solid @color3;
        color: @color3;

        &::before {
            content: @fa-var-crown;
        }
    }

    &.district {
        background-color: transparent;
        border: 1px solid #999;
        color: #999;

        &::before {
            content: @fa-var-globe-europe;
        }
    }

    &.club {
        background-color: transparent;
        border: 1px solid @color2;
        color: @color2;

        &::before {
            content: @fa-var-club;
        }
    }

    &.last-edit {
        background-color: tint(@color, 40%);

        &::before {
            content: @fa-var-hourglass;
        }
    }

    &.calendar {
        background-color: tint(@color, 40%);

        &::before {
            content: @fa-var-calendar-alt;
        }
    }

    &.conj {
        margin-top: 3px;

        &::before {
            content: @fa-var-rings-wedding;
        }
    }

    &.conj-principal {
        color: #fff;
        background-color: @color;
    }

    &.conj-rattache {
        color: @color;
        background-color: tint(@color, 80%);
    }

    &.abo {
        background-color: shade(@color, 40%);

        &::before {
            content: @fa-var-book;
        }

        &.indiv {
            background-color: shade(@color, 50%);
        }
    }

    &.abo-numeric {
        background-color: tint(@color2, 70%);
        color: @color;

        &::before {
            content: @fa-var-desktop;
        }
    }

    &.price {
        background-color: shade(@color, 50%);

        &::before {
            content: @fa-var-tag;
        }
    }

    &.acces-annuaire {
        background-color: shade(@color, 40%);

        &::before {
            content: @fa-var-desktop;
        }
    }

    &.rodi {
        background-color: @rodi;

        &::before {
            content: @fa-var-sync;
        }
    }

    &.type-fact {
        color: #fff;

        &.eshop {
            background-color: shade(@color, 30%);

            &::before {
                content: @fa-var-shopping-cart;
            }
        }

        &.annuaire {
            background-color: shade(@color, 40%);

            &::before {
                content: @fa-var-book;
            }
        }

        &.aboclub {
            background-color: @color;

            &::before {
                content: @fa-var-book-open;
            }
        }

        &.annule {
            background-color: @alert;

            &::before {
                content: @fa-var-times-circle;
            }
        }
    } // type-fact

    &.type-client {
        color: #fff;
        border: 0 none;

        &.club {
            background-color: @color3;

            &::before {
                content: @fa-var-club;
            }
        }

        &.membre {
            background-color: shade(@color3, 20%);

            &::before {
                content: @fa-var-user-tie;
            }
        }

        &.client {
            background-color: @color2;

            &::before {
                content: @fa-var-user;
            }
        }
    } // .type-client

    &.sync {
        &::before {
            content: @fa-var-sync;
        }
    }
} // .flag

// MESSAGES INFO
.msg-info {
    padding: 10px 20px;
    min-height: 50px;
    text-align: left;
    margin: auto;
    margin-bottom: 20px;
    color: #fff;
    .rounded(5px);
    .flex(row, center, flex-start);

    &::before {
        .fa;

        font-size: 2.6rem;
        margin-right: 25px;
    }

    a {
        margin: 0 5px;

        &:not(.btn) {
            color: #fff;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .cols {
        flex-grow: 1;
    }

    &.flash {
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        height: 0;
        font-size: 1.6rem;
        letter-spacing: 0.1rem;
        font-weight: 700;
        text-transform: uppercase;
        transition: all ease 0.15s;
        opacity: 0;
        padding-left: 80px;
        text-align: left;
        .rounded(0);

        &.open {
            height: 80px;
            opacity: 1;
        }
    }

    &.info {
        background-color: fadeout(@color, 85%);
        color: @color;

        &::before {
            content: @fa-var-info-circle;
        }

        a:not(.btn) {
            color: @color;
            text-decoration: underline;
        }
    }

    &.info-rodi {
        background-color: fadeout(@rodi, 70%);
        color: shade(@rodi, 30%);
        align-items: flex-start;
        position: relative;
        padding: 20px;

        &::before {
            display: inline-block;
            background-image: url('../img/logo-rodi.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 90px;
            height: 40px;
            content: '';
            .flex(row,center,center);
        }

        p {
            margin-bottom: 10px;
        }

        .rodi-date-maj {
            position: absolute;
            top: 50%;
            min-height: 70px;
            margin-top: -45px;
            right: 40px;
            margin-bottom: 0;
            .flex(column,center,center);

            i {
                font-size: 2.6rem;
                margin-bottom: 10px;
            }
        }
    }

    &.valid {
        background-color: fadeout(@valid, 5%);

        &::before {
            content: @fa-var-check;
        }
    }

    &.delete {
        background-color: fadeout(@valid, 5%);

        &::before {
            content: @fa-var-trash;
        }
    }

    &.error {
        background-color: fadeout(@alert, 5%);

        &::before {
            content: @fa-var-ban;
        }
    }

    &.warning {
        background-color: fadeout(@warning, 5%);

        &::before {
            content: @fa-var-exclamation-triangle;
        }
    }
} // .msg-info

.bloc-dico {
    position: relative;
    padding: 10px;
    padding-left: 80px;
    background-color: @rodi;
    color: #fff;
    .rounded(10px);

    > i {
        font-size: 4rem;
        position: absolute;
        top: 50%;
        left: 20px;
        margin-top: -20px;
    }
}

// ALERTES
.item-alerte {
    min-height: 60px;
    margin-bottom: 10px;
    color: shade(@color2, 30%);
    background-color: tint(@color2, 90%);
    .rounded(3px);
    .flex(row, stretch, space-between);

    &::before {
        align-self: center;
        text-align: center;
        font-size: 2.4rem;
        min-width: 30px;
        margin: 0 20px;
        color: @color2;
        .fa;
        .fas;

        content: @fa-var-info-circle;
    }

    .alert-content {
        flex-grow: 1;
        align-self: center;
        font-size: 1.3rem;
        padding: 10px;
    }

    nav {
        .flex(row, stretch, space-between);
    }

    .btn {
        min-width: 50px;
        height: 100%;
        font-size: 1.7rem;
        color: #fff;
        .rounded(0);

        &:hover {
            opacity: 0.8;
        }

        &:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }

    .btn-close {
        background-color: tint(@color2, 20%);
    }

    .btn-action {
        background-color: tint(@color2, 30%);
    }

    &.warning {
        background-color: tint(@warning, 90%);
        color: @warning;

        &::before {
            color: @warning;
            content: @fa-var-exclamation-triangle;
        }

        .btn-action {
            background-color: tint(@warning, 10%);
        }

        .btn-close {
            background-color: tint(@warning, 30%);
        }
    }

    &.alert {
        background-color: tint(@alert, 90%);
        color: @alert;

        &::before {
            color: @alert;
            content: @fa-var-times-circle;
        }

        .btn-action {
            background-color: tint(@alert, 10%);
        }

        .btn-close {
            background-color: tint(@alert, 30%);
        }
    }
} // .item-alerte

.item-transit {
    min-height: 60px;
    margin-bottom: 10px;
    color: shade(@color, 30%);
    background-color: tint(@color2, 90%);
    .rounded(3px);
    .flex(row, stretch, space-between);

    &::before {
        align-self: center;
        text-align: center;
        font-size: 2.4rem;
        min-width: 30px;
        margin: 0 20px;
        color: @color2;
        .fa;

        content: @fa-var-plane-departure;
    }

    .transit-content {
        flex-grow: 1;
        align-self: center;
        font-size: 1.3rem;
        padding: 10px;
    }

    nav {
        .flex(row, stretch, space-between);
    }

    .btn {
        min-width: 50px;
        height: 100%;
        font-size: 1.8rem;
        color: #fff;
        .rounded(0);

        &:hover {
            opacity: 0.8;
        }

        &:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &.valid {
            background-color: tint(@valid, 10%);
        }

        &.invalid {
            background-color: tint(@alert, 10%);
        }

        &.info {
            background-color: tint(@color, 10%);
        }
    }
} // .item-transit

.rodi-transit-detail {
    li {
        min-height: 40px;
        padding: 4px 10px;
        .flex();

        &:nth-child(odd) {
            background-color: #f5f5f5;
        }
    }

    .legend {
        margin-bottom: 0 !important;
    }

    .flag {
        margin-top: 0;
    }
}

.outdated-browser {
    margin-bottom: 25px;
    background-color: @alert;
    border: 1px solid @alert;
    .rounded(5px);

    h2 {
        color: #fff;
        padding: 15px;
    }

    p {
        padding: 0 10px;
        color: #fff;
        margin-bottom: 15px;
    }

    .ie {
        color: #fff;
        border: 1px solid #fff;
        padding: 2px 5px;
        font-size: 1.2rem;
        .rounded(2px);
        .flex-i(row, center, flex-start);

        i {
            margin-right: 5px;
        }
    } // .ie

    nav {
        margin-bottom: 10px;
        .flex(row, center, center);
    }

    .btn {
        color: #fff;
        border: 1px solid #fff;
        margin-left: 10px;

        &:hover {
            color: @alert;
            background-color: #fff;
        }
    }
} // .outdated-browser

//// PAGINATION //////
.pagination {
    margin-bottom: 20px;
    .flex(row, center, center);

    li {
        margin-right: 10px;

        &:only-child {
            display: none;
        }

        a {
            background-color: #eee;
            color: #333;
            height: 30px;
            width: 30px;
            .rounded(50%);
            .flex(row, center, center);

            &:hover {
                background-color: @color;
                color: #fff;
            }
        }

        &.active {
            a {
                background-color: @color;
                color: #fff;
            }
        }

        &.disabled {
            a,
            a:hover {
                background-color: #fff;
                color: #333;
                cursor: default;
            }
        }
    }
} // .pagination

//// POPUP /////
body {
    &.mode-popup {
        &::after {
            display: block;
            content: "";
            position: fixed;
            height: 100vh;
            top: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.7);
            z-index: 9998;
        }
    }
}

.popup {
    display: none;
    color: #333;
    position: fixed;
    top: 8%;
    bottom: 8%;
    left: 50%;
    height: auto;
    width: 90%;
    max-width: 790px;
    margin-left: -395px;
    z-index: 9999;
    overflow-y: auto;

    @media screen and (max-width: 880px) {
        left: 5%;
        right: 5%;
        top: 5%;
        bottom: 5%;
        width: auto;
        max-width: 90%;
        margin-left: 0;
    }

    @media @mobile {
        /* 640 */
        left: 0;
        right: 0;
        max-width: 100%;
        top: 0;
        bottom: 0;
        overflow-y: scroll;
    }

    .popup-heading {
        background-color: tint(@color, 20%);
        color: #fff;
        padding: 0 15px;
        height: 50px;
        margin-bottom: 0;
        .flex(row, center, flex-start);

        i {
            margin-right: 10px;
            color: tint(@color2, 50%);
            font-size: 1.8rem;
        }
    }

    .popup-content {
        background-color: #fff;
        padding: 15px;
        padding-top: 25px;
        text-align: left;

        h2 {
            text-transform: uppercase;
        }

        .legend {
            margin-bottom: 15px;
        }
    }

    .btn-del {
        border-color: transparent;
    }

    .cols {
        > div {
            width: 50%;

            &.auto {
                width: auto;
            }

            &.full {
                flex-grow: 1;
            }

            &:first-child {
                margin-right: 20px;
            }
        }

        .last {
            margin-left: 20px;
        }
    }

    &.open {
        display: block;
    }

    > .close-popup {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #fff;

        i {
            font-size: 2.8rem;
        }

        &:hover {
            color: @color2;
        }

        @media @mobile {
            position: fixed;
        }
    }
} // .popup

[data-tooltip] {
    position: relative;
    .transition;

    &[data-tooltip=""] {
        &::before,
        &::after {
            display: none;
        }
    }

    &::before {
        position: absolute;
        left: 50%;
        margin-left: -3px;
        top: 10px;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 10px 6px;
        border-color: transparent transparent @color transparent;
        opacity: 0;
        z-index: 999;
        .transition;

        .logs & {
            left: auto;
            right: 60px;
            margin-left: 0;
            margin-top: -6px;
            top: 50%;
            border-width: 6px 0 6px 10px;
            border-color: transparent transparent transparent @color;
        }
    }

    &::after {
        content: attr(data-tooltip);
        position: absolute;
        left: 0;
        right: 0;
        top: 20px;
        background-color: @color;
        color: #fff;
        padding: 10px;
        font-size: 1.25rem;
        opacity: 0;
        z-index: 999;
        .rounded(4px);
        .flex(row, center, center);
        .transition;

        .logs & {
            height: 40px;
            text-transform: uppercase;
            left: auto;
            right: 70px;
            top: 50%;
            margin-top: -20px;
        }
    }

    &:hover {
        &::before {
            opacity: 1;
            top: 30px;

            .logs & {
                top: 50%;
            }
        }

        &::after {
            opacity: 1;
            top: 40px;

            .logs & {
                top: 50%;
            }
        }
    }
} // [data-tooltip]

[data-error] {
    position: relative;
    color: @alert;
    .transition;

    & + input {
        border: 2px solid @alert;
        color: @alert;
    }

    &::before,
    &::after {
        position: absolute;
        display: none;
        z-index: 999;
        .transition;
    }

    &::before {
        left: 20px;
        top: 17px;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 8px 6px;
        border-color: transparent transparent @alert transparent;
    }

    &::after {
        content: attr(data-error);
        white-space: pre-line;
        text-transform: none;
        left: 0;
        right: 0;
        top: 25px;
        background-color: @alert;
        color: #fff;
        padding: 10px;
        font-size: 1.25rem;
        .rounded(4px);
    }

    &:hover {
        &::before {
            display: block;
        }

        &::after {
            .flex(row, center, center);
        }
    }
} // [data-error]

// STATUT
.statut {
    padding: 0 5px;
    text-transform: none;
    font-size: 1.4rem;
    color: @color;
    font-weight: 700;
    .rounded(3px);
    .flex-i(row, center, flex-start);

    a& {
        &:hover {
            color: @color3;

            i {
                color: @color3;
            }
        }
    }

    i {
        margin-right: 5px;
        font-size: 1.2rem;
        .fas;
        .transition;
    }
} // .statut

// LINK FILE

.link-file {
    margin-bottom: 15px;
    min-height: 60px;

    h3 {
        margin-bottom: 5px;
    }

    .btn-del {
        border: 0 none;
        color: @color2;
    }

    &.valid {
        h3 {
            color: @valid;

            &::before {
                font-size: 1.8rem;
                margin-right: 10px;
                content: @fa-var-check-circle;
                color: @valid;
                .fa;
                .fas;
            }
        }
    }

    &.invalid {
        h3 {
            color: @alert;

            &::before {
                font-size: 1.8rem;
                margin-right: 10px;
                content: @fa-var-ban;
                color: @alert;
                .fa;
                .fas;
            }
        }
    }
} // .link-file

// SLIDER
.slider {
    position: relative;
    padding: 0 60px;

    .item-slider {
        padding: 20px 20px 0 20px;
    }
} // .slider

.nav-slider {
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;

    a {
        position: absolute;
        top: -10px;
        font-size: 3rem;
        .flex(row, center, center);

        &:hover {
            i {
                font-weight: 700;
            }
        }

        &.btn-prev {
            left: 0;
        }

        &.btn-next {
            right: 0;
        }

        &.slick-disabled {
            opacity: 0.4;
            cursor: not-allowed;

            &:hover {
                i {
                    font-weight: 300;
                }
            }
        }
    }
} // .nav-slider

// BLOC PHOTO
.bloc-img {
    position: relative;
    margin-bottom: 25px;
    .flex-i(row, center, center);

    .list-btn {
        position: absolute;
        right: 20px;
        top: 20px;

        .btn {
            display: flex;
            max-width: 40px;
            margin-bottom: 10px;
            .rounded(50%);
        }
    } // .list-btn
} // .bloc-img

.disp-abonnement {
    display: none;

    &.open {
        display: block;
    }
}

// LOADER
.loader {
    position: fixed;
    inset: 0;
    z-index: 9999;
    background-color: fadeout(#fff, 10%);
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(6px);

    &.actif {
        display: flex;
    }

    .anim-loader-content {
        margin-bottom: 40px;
        min-height: 120px;
        .flex(row,center,center);
    }

    p {
        text-transform: uppercase;
        font-weight: 700;
        color: @color;
    }
}

.anim-loader {
    color: @color;
    position: relative;
    font-size: 11px;
    background-color: @color;
    animation: escaleY 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
    animation-delay: -0.16s;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 2em;
        background: @color;
        width: 1em;
        height: 4em;
        animation: escaleY 1s infinite ease-in-out;
    }

    &::before {
        left: -2em;
        animation-delay: -0.32s;
    }
}

@keyframes escaleY {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

/////// FOOTER //////

footer {
    color: @color2;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.25rem;
    height: 60px;
    background-color: tint(@color2, 85%);
    .flex(row,center,center);

    a {
        color: @color2;

        &:hover {
            color: @color;
        }
    }

    .pw-powa {
        margin-left: 60px;
        .flex(row,center,center);

        i {
            padding: 0 5px;
        }
    }

    .legal {
        .flex(row,center,center);

        a {
            padding: 0 5px;
        }
    } // .legal
}

//// LOGIN //////

.login {
    position: relative;
    padding-top: 60px;

    &::before {
        content: '';
        display: block;
        height: 15px;
        background-color: @color;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .content {
        .wrap {
            max-width: 330px;
        }
    }

    h2 {
        text-align: center;
    }

    input[type="submit"] {
        margin: auto;
        .flex;
    }

    .logo {
        width: 300px;
        height: 180px;
        display: block;
        margin: auto;
        margin-bottom: 80px;
    }

    .lost-pass {
        display: block;
        text-align: center;
        margin: 10px;
        font-size: 1.2rem;
        letter-spacing: 0.02rem;
        padding-top: 20px;

        &:hover {
            text-decoration: underline;
        }
    }
}

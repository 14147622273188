// HEADER //

header {
    height: 60px;
    position: relative;
    margin-bottom: 35px;
    background-color: tint(@color2, 85%);

    &::before {
        content: "";
        display: block;
        height: 3px;
        background-color: @color;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}

.toggle-nav {
    position: fixed;
    top: 22px;
    left: 24px;
    width: 30px;
    height: 18px;
    z-index: 999;

    &::after {
        border: 1px solid tint(@color, 50%);
        content: "MENU";
        padding: 5px;
        .rounded(3px);
        .transition;
    }

    &:hover {
        &::after {
            background-color: tint(@color, 50%);
            color: #fff;
        }
    }

    &.menu-open {
        position: fixed;

        &::after {
            color: #fff;
            border-color: @color;
            content: "FERMER";
            background-color: @color;
        }

        &:hover {
            &::after {
                background-color: #fff;
                color: @color;
            }
        }
    }
}

.sidebar {
    position: fixed;
    font-weight: 300;
    padding-top: 78px;
    height: 100%;
    width: 280px;
    left: -280px;
    top: 0;
    background-color: darken(@color, 5%);
    overflow-y: scroll;
    z-index: 998;
    color: #fff;
    .transition;

    &.open {
        left: 0;
    }

    h3 {
        color: @color3;
        text-align: left;
        text-transform: uppercase;
        min-height: 35px;
        height: 35px;
        font-size: 1.2rem;
        letter-spacing: 0.1rem;
        font-weight: 700;
        margin-bottom: 5px;
        padding-left: 25px;
        .flex(row, center, flex-start);
    }

    .list-item {
        margin-bottom: 5px;
        .flex(column, center, center);

        a {
            height: 30px;
            color: #fff;
            position: relative;
            width: 100%;
            font-size: 1.1rem;
            letter-spacing: 0.07rem;
            font-weight: 600;
            text-align: left;
            text-transform: uppercase;
            .flex;

            > i {
                flex-grow: 0;
                text-align: center;
                font-size: 1.6rem;
                min-width: 55px;

                &.fa-angle-right {
                    text-align: right;
                    padding-right: 15px;
                }
            }

            span {
                flex-grow: 1;
            }

            &.actif,
            &:hover {
                background-color: darken(@color, 15%);

                &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 2px;
                    background-color: #fff;
                }
            }

            &[href="#"] {
                opacity: 0.5;
            }
        }
    } // .list-item
} // .sidebar

.logo {
    height: 30px;
    width: 100px;
    .flex(row, center, flex-start);

    a&:hover {
        opacity: 0.8;
    }

    &.dev {
        &::after {
            content: "dev";
            text-transform: uppercase;
            font-size: 1.8rem;
            padding: 0 5px;
            font-weight: 700;
            margin-left: 105px;
            color: @alert;
            border: 2px solid @alert;
            .rounded(5px);
        }
    }
}

.admin-flags {
    margin-right: 15px;
    .flex(row,center,flex-end);

    li {
        margin-left: 5px;
        color: #fff;
        font-size: 1.3rem;
        text-transform: uppercase;
        height: 25px;
        padding: 0 5px;
        .rounded(3px);
        .flex(row, center, center);

        i {
            margin-right: 5px;
        }

        &.readonly-flag {
            background-color: @alert;
        }

        &.validannuaire-flag {
            background-color: @warning;
        }
    }
} // .admin-flags

.main-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .user {
        color: @color;
        display: flex;
        align-items: center;

        strong {
            text-transform: uppercase;
        }

        a {
            font-size: 2rem;
            margin-left: 15px;
            color: @color;

            &:hover {
                color: @color2;
            }
        }
    }

    .username {
        i {
            font-size: 1.7rem;
            height: 35px;
            width: 35px;
            color: #fff;
            background-color: @color;
            margin-right: 10px;
            .flex-i(row,center,center);
            .rounded(50%);
        }
    }
} // .main-header
